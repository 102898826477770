import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';

export default class RedcoreUploadAdapter extends Plugin {
    static get requires() {
        return [FileRepository];
    }

    static get pluginName() {
        return 'RedcoreUploadAdapter';
    }

    init() {
        this.editor.plugins.get(FileRepository).createUploadAdapter = loader => {
            return new RedcoreFileHandler(loader, this.editor);
        };
    }
}

class RedcoreFileHandler {
    constructor(loader, editor) {
        this.loader = loader;
        this.editor = editor;
    }

    upload() {
        return redcoreObject.handleCkeditorfileUpload(this.loader, this.editor);
    }


    abort() {
    }
}